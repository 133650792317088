import { graphql } from "gatsby";
import styled from "styled-components";
import useContentfulVariation from "../hooks/contentful/useContentfulVariation";

const NavigationElement = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 1rem;

  &.active {
    text-decoration: underline;
  }
`;

const SmallRadialGradientElement = styled.span`
  width: 773px;
  height: 773px;
  position: absolute;
  right: -386px;
  top: -300px;

  border-radius: 773px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 230, 102, 0.6) 17.67%,
    rgba(255, 230, 102, 0) 100%
  );
`;

const LargeRadialGradientElement = styled(SmallRadialGradientElement)`
  width: 977px;
  height: 977px;
  right: -489px;
  top: 402px;

  border-radius: 977px;
`;

const ProductListPage = ({ data }) => {
  const { contentfulPageVariation } = data;
  const { flag, defaultContent, contentVariations } = contentfulPageVariation;
  const contentfulPlp = useContentfulVariation(
    flag,
    defaultContent,
    contentVariations,
  );
  const activePanel = contentfulPlp?.linkSets?.[0]?.navigationpanel?.[0];

  const { title, linkSets } = contentfulPlp;
  // Multiple linkSets means that this is a primary page (L1, i.e. Pregnancy, Daily Health)
  const isPrimaryPage = linkSets.length > 1;

  let links = linkSets[0].links;
  if (isPrimaryPage) {
    links = [
      {
        // This is the `All Pregnancy` link for primary page (L1)
        contentful_id: contentfulPlp.contentful_id,
        text: `All ${activePanel.title}`,
        to: {
          contentful_id: contentfulPlp.contentful_id,
          slug: contentfulPlp.slug,
        },
      },
      ...linkSets.map((linkSet) => {
        return {
          contentful_id: linkSet.contentful_id,
          text: linkSet.title,
          to: linkSet.links[0].to,
        };
      }),
    ];
  } else {
    const { contentful_id, title, slug } = contentfulPlp.parentPlp;
    links = [
      {
        // This is the `All Pregnancy` link for secondary page (L2)
        contentful_id: contentful_id,
        text: `All ${title}`,
        to: {
          contentful_id: contentful_id,
          slug: slug,
        },
      },
      ...links,
    ];
  }

  return (
    <>
      <div className="my-6 py-6 px-4">
        <p>Shop {!isPrimaryPage && `/ ${activePanel?.title}`}</p>
        <h1>{title}</h1>
        <ul className="d-flex flex-row">
          {links.map((link) => {
            return (
              <NavigationElement
                key={link.contentful_id}
                className={
                  link.to.contentful_id === contentfulPlp.contentful_id
                    ? "active"
                    : ""
                }
              >
                <a href={`/${link?.to?.slug}`}>{link.text}</a>
              </NavigationElement>
            );
          })}
        </ul>
        <SmallRadialGradientElement />
        <LargeRadialGradientElement />
      </div>
    </>
  );
};

export const query = graphql`
  fragment ProductListPageFragment on ContentfulPlp {
    contentful_id
    title
    slug
    linkSets {
      contentful_id
      title
      navigationpanel {
        contentful_id
        title
      }
      links {
        contentful_id
        text
        to {
          ... on ContentfulShopLandingPage {
            contentful_id
            slug
          }
        }
      }
    }
    parentPlp {
      contentful_id
      title
      slug
    }
  }

  query ScalableProductListPageQuery(
    $locale: String!
    $contentful_id: String!
  ) {
    contentfulPageVariation(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      flag
      defaultContent {
        ...ProductListPageFragment
      }
      contentVariations {
        ...ProductListPageFragment
      }
    }
  }
`;

export default ProductListPage;
